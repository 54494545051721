//Used in login.js and signup.js
function Welcome() {
  return (
    <div id="welcome" className="place-self-center">

      <p className="text-appName">Mail</p>

      <p className="py-5 text-welcomeText">Contact your friends around the globe on
      <p>a minimalist and simple to use app.</p> </p>

    </div>
  )
}

export default Welcome;
